<template>
  <b-modal id="examChecklistModal" :title="title" size="xl" @show="onShow">
    <b-form @submit.stop.prevent="">
      <b-form-row>
        <b-col cols="6">
          <label>Anbieter/Kurs:</label>
          <b-form-input v-model="course" placeholder="Anbieter/Kurs..." trim/>
        </b-col>
        <b-col cols="6">
          <label>Prüfungstermin:</label>
          <b-form-input v-model="examDate" placeholder="Prüfungstermin..." trim/>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col>
          <label>Technische Hinweise:</label>
          <b-form-textarea v-model="technicalInformation" rows="4"/>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col>
          <label>Links/Ansprechpartner:</label>
          <b-form-textarea v-model="contactInformation" rows="4"/>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col>
          <label>Wiederholungsprüfung:</label>
          <b-form-textarea v-model="repeatExamInformation" rows="4"/>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col>
          <label>Notizen:</label>
          <b-form-textarea v-model="note" rows="4"/>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col>
          <b-link :href="markdownLink" target="_blank">Markdown - Erklärung</b-link>
        </b-col>
      </b-form-row>
    </b-form>
    <template #modal-footer="{ cancel }">
      <b-button v-if="method === 'edit'" variant="outline-danger" class="mr-auto" @click="removeRow()">
        <b-spinner v-show="loadingRemoveButton" small/>
        Löschen
      </b-button>
      <b-button :variant="buttonVariantDanger" @click="cancel()">Abbrechen</b-button>
      <b-button :variant="buttonVariantSuccess" @click="ok" :disabled="disabledButton">
        <b-spinner v-show="loadingOkButton" small/>
        OK
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {putRequest} from "@/modules/requests";
import {mapGetters} from "vuex";

export default {
  name: "ExamModal",
  props: ['method', 'courseEntry'],
  data() {
    return {
      id: null,
      course: '',
      examDate: '',
      technicalInformation: '',
      contactInformation: '',
      repeatExamInformation: '',
      note: '',
      loadingOkButton: false,
      loadingRemoveButton: false,
      markdownLink: 'https://experienceleague.adobe.com/docs/contributor/contributor-guide/writing-essentials/markdown.html?lang=de'
    }
  },
  watch: {
    method() {
      this.onShow()
    },
    courseEntry() {
      this.onShow()
    }
  },
  methods: {
    ok() {
      switch(this.method) {
        case 'edit':
          this.updateRow();
          break;
        case 'add':
          this.addRow();
          break;
      }
    },
    onShow() {
      if(this.method === 'edit' && this.courseEntry) {
        this.id = this.courseEntry.id
        this.course = this.courseEntry.course
        this.examDate = this.courseEntry.exam_date
        this.technicalInformation = this.courseEntry.technical_information
        this.contactInformation = this.courseEntry.contact_information
        this.repeatExamInformation = this.courseEntry.repeat_exam_information
        this.note = this.courseEntry.note
      }
      else {
        this.reset()
      }
    },
    reset() {
      this.id = null
      this.course = ''
      this.examDate = ''
      this.technicalInformation = ''
      this.contactInformation = ''
      this.repeatExamInformation = ''
      this.note = ''
    },
    refresh() {
      this.$emit('refresh');
    },
    async addRow() {
      this.loadingOkButton = true

      let data = {
        course: this.course,
        exam_date: this.examDate,
        technical_information: this.technicalInformation,
        contact_information: this.contactInformation,
        repeat_exam_information: this.repeatExamInformation,
        note: this.note
      }

      await putRequest('exam/admin/add', data, this, 'Neuer Eintrag wurde erfolgreich erstellt.', 'Neuer Eintrag konnte nicht erstellt werden.')
          .then(() => {
            this.loadingOkButton = false
            this.refresh()
            this.$bvModal.hide('examChecklistModal')
          })
          .catch(() => {
            this.loadingOkButton = false
          })
    },
    async updateRow() {
      this.loadingOkButton = true

      let data = {
        exam_checklist_id: this.id,
        course: this.course,
        exam_date: this.examDate,
        technical_information: this.technicalInformation,
        contact_information: this.contactInformation,
        repeat_exam_information: this.repeatExamInformation,
        note: this.note
      }

      await putRequest('exam/admin/update', data, this, 'Der Eintrag wurde erfolgreich aktualisiert.', 'Der Eintrag konnte nicht aktualisiert werden.')
          .then(() => {
            this.loadingOkButton = false
            this.refresh()
            this.$bvModal.hide('examChecklistModal')
          })
          .catch(() => {
            this.loadingOkButton = false
          })
    },
    async removeRow() {
      this.loadingRemoveButton = true
      await putRequest('exam/admin/delete/' + this.id, null, this, 'Der Eintrag wurde erfolgreich gelöscht.', 'Der Eintrag konnte nicht gelöscht werden.')
          .then(() => {
            this.loadingRemoveButton = false
            this.refresh();
            this.$bvModal.hide('examChecklistModal')
          })
          .catch(() => {
            this.loadingRemoveButton = false
          })
    }
  },
  computed: {
    ...mapGetters(['userColorAccessibility']),
    title() {
      if(this.method === 'edit') {
        return 'Eintrag ändern'
      }
      return 'Eintrag hinzufügen'
    },
    disabledButton() {
      return !(this.course && this.examDate);
    },
    buttonVariantSuccess() {
      if(this.userColorAccessibility === 'red-green') { return 'success-deuteranopia' }
      return 'success'
    },
    buttonVariantDanger() {
      if(this.userColorAccessibility === 'red-green') { return 'danger-deuteranopia' }
      return 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
