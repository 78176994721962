<template>
  <div>
    <b-row>
      <b-col>
        <SubHeader class="mb-2">
          <b-row class="mt-2 mb-1">
            <b-col cols="auto">
              <b-icon-arrow-clockwise v-if="!reloading" :variant="iconVariant" @click="refresh" font-scale="1.5"
                                      class="clickable computer-header-icon"/>
              <b-icon-arrow-clockwise v-else :variant="iconVariant" font-scale="1.5" animation="spin"
                                      class="computer-header-icon"/>
              <small class="ml-2">Letzte Aktualisierung: {{ lastDataFetched }}</small>
            </b-col>
            <b-col cols="2">
              <b-form-input v-model="search" debounce="500" size="sm" placeholder="Suche nach Kursen..."/>
            </b-col>
            <b-col cols="auto" v-if="fgExam" class="ml-auto">
              <b-button variant="primary" size="sm" @click="add">Neuer Eintrag</b-button>
            </b-col>
            <b-col cols="auto" :class="{ 'ml-auto': !fgExam}">
              <b-button-group size="sm">
                <b-button variant="secondary" @click="downloadChecklist">
                  Download als .{{ downloadFormat }}
                </b-button>
                <b-dropdown right size="sm">
                  <b-dropdown-item @click="downloadFormat = 'csv'">als CSV</b-dropdown-item>
                  <b-dropdown-item @click="downloadFormat = 'xlsx'">als XLSX</b-dropdown-item>
                </b-dropdown>
              </b-button-group>
            </b-col>
          </b-row>
        </SubHeader>
        <ExamChecklistTable v-if="coursesFiltered" :key="tableKey" :tableItems="coursesFiltered" :loading="loading"
                            :fgExam="fgExam" @edit-entry="edit"/>
      </b-col>
    </b-row>
    <ScrollToTop/>
    <ExamModal v-if="fgExam" :method="method" :courseEntry="courseEntry" @refresh="refresh"/>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import dayjs from "dayjs";
import {mapActions, mapGetters} from "vuex";
import {getRequest} from "@/modules/requests";
import ExamModal from "@/components/fg-exam/ExamModal.vue";
import SubHeader from "@/components/structure/SubHeader.vue";
import ScrollToTop from "@/components/utility/ScrollToTop.vue";
import ExamChecklistTable from "@/components/fg-exam/ExamChecklistTable.vue";

export default {
  name: "ExamChecklist",
  components: {
    ExamModal,
    SubHeader,
    ScrollToTop,
    ExamChecklistTable
  },
  data() {
    return {
      loading: true,
      tableItems: null,
      tableKey: 0,
      search: '',
      downloadFormat: 'csv',
      courseEntry: null,
      method: null,
      reloading: false,
      lastDataFetched: '-',
      fgExam: this.$route.meta.fgExam
    }
  },
  methods: {
    ...mapActions(['setLoadingState']),
    add() {
      this.method = 'add'
      this.$bvModal.show('examChecklistModal')
    },
    edit(data) {
      this.method = 'edit'
      this.courseEntry = data
      this.$bvModal.show('examChecklistModal')
    },
    async refresh() {
      this.reloading = true
      if(this.fgExam) {
        await this.getAdminChecklist()
      }
      else {
        await this.getChecklist()
      }
      this.lastDataFetched = dayjs().format('HH:mm:ss')
      this.reloading = false
    },
    async getChecklist() {
      await getRequest('/exam/checklist', null, this)
          .then((response) => {
            this.tableItems = response.data
            this.tableKey += 1;
          })
    },
    async getAdminChecklist() {
      await getRequest('/exam/admin/checklist', null, this)
          .then((response) => {
            this.tableItems = response.data
            this.tableKey += 1;
          })
    },
    async downloadChecklist() {
      let config = {
        method: 'get',
        url: 'downloads/exam',
        params: {
          file_type: this.downloadFormat
        },
        responseType: 'blob'
      }
      await axios(config)
          .then((response) => {
            const blob = new Blob([response.data], {type: response.headers["content-type"]})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = response.headers["content-disposition"].split('=')[1]
            link.click()
            URL.revokeObjectURL(link.href)
          })
    }
  },
  computed: {
    ...mapGetters(['userThemeLight']),
    iconVariant() {
      if(this.userThemeLight) { return 'dark' }
      return 'light'
    },
    coursesFiltered() {
      return _.filter(this.tableItems, (entry) => {
        return entry.course.toLowerCase().includes(this.search.toLowerCase())
      })
    },
  },
  async mounted() {
    this.setLoadingState(true)
    await this.refresh()
    await window.scrollTo(0, 0)
    this.setLoadingState(false)
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
</style>
